import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { HTMLContent } from '../components/Content';
import BioPageTemplate from '../components/BioPageTemplate';
import Layout from '../components/Layout';

const BioPage = (props) => {
  const {
    data: { markdownRemark: post },
  } = props;

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.meta_title}</title>
        <meta name="description" content={post.frontmatter.meta_description} />
      </Helmet>
      <BioPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        headshot={post.frontmatter.headshot}
        content={post.html}
      />
    </Layout>
  );
};

BioPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BioPage;

export const bioPageQuery = graphql`
  query BioPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        headshot {
          childImageSharp {
            fluid(maxWidth: 1075, quality: 72) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        meta_title
        meta_description
      }
    }
  }
`;
