import React from 'react';
import Content from '../Content';
import PropTypes from 'prop-types';
import ProgressiveImageContainer from '../ProgressiveImageContainer';

const BioPageTemplate = (props) => {
  const { title, content, contentComponent, headshot } = props;
  const PageContent = contentComponent || Content;

  return (
    <div>
      <section className="hero is-primary is-bold is-medium">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h1 className="title">{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <figure className="image">
                  <ProgressiveImageContainer
                    imgStyle={{ borderRadius: '290486px' }}
                    image={headshot}
                    alt={title}
                  />
                </figure>
              </div>
              <div className="section">
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

BioPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  headshot: PropTypes.string,
};

export default BioPageTemplate;
